<template>
  <div class="news-index">
    <!--通知公告-->
    <!-- <div class="con-item notice">
      <div class="container">
        <div class="con-title con-title-1">
          <span>通知公告</span>
          <a class="more" @click="goRouter('3.3.')">更多通知</a>
        </div>
        <div class="notice-list">
          <a-list
            :grid="{ gutter: 40, column: 2 }"
            :data-source="dataNotice.slice(0, 6)"
          >
            <a-list-item slot="renderItem" slot-scope="item">
              <a-card :bordered="false">
                <template slot="title">
                  <i></i>
                  <a
                    class="overflow-dian"
                    :title="item.title"
                    @click="goDetails(item.id, '通知公告')"
                    >{{ item.title }}</a
                  >
                </template>
              </a-card>
            </a-list-item>
          </a-list>
        </div>
      </div>
    </div> -->
    <!--活动中心-->
    <div class="con-item activity">
      <div class="container">
        <div class="con-title con-title-1">
          <span>新闻资讯</span>
          <a class="more" @click="goRouter('3.4.')">更多资讯</a>
        </div>
        <ul class="activity-content">
          <li 
            :class="['activity-item-row']" 
            v-for="(item, index) in dataActive" 
            :key="item.id" 
            >
            <div class="is-show-img" v-if="aciOddIndex == index || aciEvenIndex == index">
              <div class="img-cover">
                <img alt="" :src="item.imgurl" @click="goDetails(item.id, '活动中心')" />
              </div>
              <div class="content-col">
                <p class="" @click="goDetails(item.id, '活动中心')">{{ item.title }}</p>
              </div>
            </div>
            <div class="no-show-img" v-else>
              <p class="title-col" @click="goDetails(item.id, '活动中心')">
                <span>{{ item.title }}</span>
              </p>
              <span class="bar"></span>
              <span class="date-col">{{ item.date }}<span>-</span>{{ item.year }}</span>
            </div>
          </li>
        </ul>
        <!-- <a-row>
          <a-col :span="10" class="activity-show">
            <a-carousel effect="fade" autoplay dotsClass="activity-dots" v-if="dataActiveCarousel.length">
              <a-card :bordered="false" v-for="(item) in dataActiveCarousel" :key="item.id">
                <template slot="cover">
                  <img alt="example" :src="item.imgurl"  @click="goDetails(item.id, '活动中心')" />
                </template>
                <a-card-meta>
                  <template slot="title">
                    <a
                      class="overflow-more-two ahover"
                      :title="item.title"
                      @click="goDetails(item.id, '活动中心')"
                      >{{ item.title }}</a
                    >
                  </template>
                </a-card-meta>
              </a-card>
            </a-carousel>
          </a-col>
          <a-col :span="14" class="activity-list">
            <a-row :gutter="48" class="first-activity">
              <a-col :span="9" class="pic">
                <a-card :bordered="false">
                  <template slot="cover">
                    <img alt="" :src="dataActive[3] ? dataActive[3].imgurl : ''" @click="goDetails(dataActive[1].id, '活动中心')" />
                  </template>
                </a-card>
              </a-col>
              <a-col :span="15" class="txt">
                <h3>
                  <a
                    class="ahover overflow-more-two"
                    :title="dataActive[3] ? dataActive[3].title : ''"
                    @click="goDetails(dataActive[3].id, '活动中心')"
                    >{{ dataActive[3] ? dataActive[3].title : '' }}</a
                  >
                </h3>
              </a-col>
            </a-row>
            <a-list
              item-layout="horizontal"
              :data-source="dataActive.slice(4, 10)"
            >
              <a-list-item slot="renderItem" slot-scope="item">
                <a-list-item-meta>
                  <a
                    slot="title"
                    :title="item.title"
                    class="overflow-more-two"
                    @click="goDetails(item.id, '活动中心')"
                  >
                    {{ item.title }}
                  </a>
                </a-list-item-meta>
                <div class="pubdate">
                  <p>{{ item.date }}<span>-</span>{{ item.year }}</p>
                </div>
              </a-list-item>
            </a-list>
          </a-col>
        </a-row> -->
      </div>
    </div>
    <!--党建专栏-->
    <div class="con-item party">
      <div class="container">
        <div class="con-title con-title-2">
          <span>党建专栏</span>
          <div v-if="total > 4">
            <a @click="goRouter('3.2.')" class="more">更多内容</a>
          </div>
        </div>
        <div class="party-list">
          <a-button
            shape="circle"
            icon="left"
            class="arrow-l"
            @click="recomPartyLess"
          />
          <a-button
            shape="circle"
            icon="right"
            class="arrow-r"
            @click="recomPartyAdd"
          />
          <a-list :grid="{ gutter: 30, column: 4 }" :data-source="dataParty">
            <a-list-item slot="renderItem" slot-scope="item">
              <a-card :bordered="false">
                <template slot="cover">
                  <img alt="example" :src="item.imgurl" @click="goDetails(item.id, '党建专栏')" />
                </template>
                <a-card-meta>
                  <a slot="title">
                    <span @click="goDetails(item.id, '党建专栏')">{{
                      item.title
                    }}</span>
                  </a>
                </a-card-meta>
              </a-card>
            </a-list-item>
          </a-list>
          <!-- <a-pagination
            simple
            v-model="current"
            v-if="total > 4"
            :pageSize="4"
            @change="changePage"
            :total="total"
          /> -->
        </div>
      </div>
    </div>
    <!--媒体报道-->
    <div class="con-item media-report">
      <div class="container">
        <div class="con-title con-title-1 con-title-white">
          <span>媒体报道</span>
          <a @click="goRouter(mediaId)" class="more">更多报道</a>
        </div>
        <div class="report-list">
          <a-list :grid="{ gutter: 60, column: 2 }" :data-source="dataReport">
            <a-list-item slot="renderItem" slot-scope="item">
              <a-card :bordered="false">
                <template slot="title">
                  <i></i>
                  <a :title="item.title" @click="goDetails(item.id, '媒体报道')">{{ item.title }}</a>
                  <span>{{ item.pubdate }}</span>
                </template>
              </a-card>
            </a-list-item>
          </a-list>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import * as api from "@/services/index.js";
import * as apiNote from "@/services/newsList.js";
import * as apiTheme from "@/services/home.js";
import moment from "moment";
export default {
  name: "NewsIndex",
  components: {},
  data() {
    return {
      dataNotice: [],
      dataActive: [],
      aciOddIndex: 0,
      aciEvenIndex: 1,
      // dataActiveCarousel: [],
      dataParty:[],
      dataReport:[],
      Ddoi: "",
      total: 0,
      pagesize: 3,
      current: 1,
      mediaId: "",
    };
  },
  created() {
    //获取推荐栏目
    this.getRecommendList();
    //查询媒体报道栏目id
    this.getAllLevelTheme();
  },
  methods: {
    recomPartyLess(){
      if (this.current === 1) {
        // this.$message.warning("已经是第一页了！");
        return;
      }
      this.current--;
      this.changePage();
    },
    recomPartyAdd(){
      if (
        this.current ===
        (this.total % 4 === 0
          ? this.total / 4
          : Math.floor(this.total / 4) + 1)
      ) {
        // this.$message.warning("已经是最后一页了！");
        this.current = 1;
        this.changePage();
        return;
      }
      this.current++;
      this.changePage();
    },
    getAllLevelTheme() {
      let vm = this;
      apiTheme.getAllLevelTheme("3.").then((res) => {
        if (res.data.success) {
          res.data.data.map(function (item) {
            if (item.themename === "媒体报道") {
              vm.mediaId = item.id;
              //查詢媒體報道栏目列表
              vm.getNoteList(vm.mediaId);
            }
          });
        }
      });
    },
    //查看新闻详情
    goDetails(doi) {
      this.$router.push({ name: "NewsDetail", params: { doi: doi } });
    },
    // 跳转查看更多
    goRouter(id) {
      this.$router.push({ name: "NewsList", params: { id: id } });
    },
    //查询党建专栏
    getRecommendNoteD(doi, id) {
      let obj = {
        pageSize: 4,
        pageNumber: 1,
        classId: id,
        columnId: doi,
      };
      api.getRecommendNote(obj).then((res) => {
        if (res.data.success) {
          if (id === "3.2.") {
            //通知公告
            this.dataParty = res.data.data.list || [];
            this.total = res.data.data.count;
            this.pagesize = 4;
            this.dataParty.map(function (item) {
              item.pubdate = moment(item.pubdate).format('YYYY-MM-DD')
              item.imgurl =
                process.env.VUE_APP_API_BASE_URL +
                "/download?fileCode=" +
                item.sysFldCoverpath;
            });
          }
        }
      });
    },
    // 党建专栏分页
    changePage() {
      let obj = {
        pageSize: 4,
        pageNumber: this.current,
        columnId: this.Ddoi,
        classId: "3.2.",
      };
      api.getRecommendNote(obj).then((res) => {
        if (res.data.success) {
          //通知公告
          this.dataParty = res.data.data.list || [];
          this.total = res.data.data.count;
          this.pagesize = 4;
          this.dataParty.map(function (item) {
            item.pubdate = moment(item.pubdate).format('YYYY-MM-DD')
            item.imgurl =
              process.env.VUE_APP_API_BASE_URL +
              "/download?fileCode=" +
              item.sysFldCoverpath;
          });
        }
      });
    },
    //获取推荐栏目
    getRecommendList() {
      let vm = this;
      api.getRecommendList().then((res) => {
        if (res.data.success) {
          let list = res.data.data;
          list.map(function (data) {
            if (data.columnname === "新闻资讯-通知公告") {
              //查询推荐  首页通知公告
              vm.getRecommendNote(data.doi, "3.3.");
            }
            if (data.columnname === "新闻资讯-活动中心") {
              //查询推荐  首页活动中心
              vm.getRecommendNote(data.doi, "3.4.");
            }
            if (data.columnname === "新闻资讯-党建专栏") {
              //查询推荐  首页活动中心
              vm.getRecommendNoteD(data.doi, "3.2.");
              vm.Ddoi = data.doi;
            }
            // if (data.columnname === "首页-重磅图书推荐") {
            //   //查询推荐  首页活动中心
            //   vm.getRecommendbook(data.doi);
            //   vm.bookDoi = data.doi;
            // }
          });
        }
      });
    },
    //查询通知公告
    getRecommendNote(doi, id) {
      let obj = {
        pageSize: 10,
        pageNumber: 1,
        classId: id,
        columnId: doi,
      };
      api.getRecommendNote(obj).then((res) => {
        if (res.data.success) {
          if (id === "3.3.") {
            //通知公告
            this.dataNotice = res.data.data.list || [];
            this.dataNotice.map(function (item) {
              item.pubdate = moment(item.pubdate).format('YYYY-MM-DD')
            });
          }
          if (id === "3.4.") {
            //活动中心
            this.dataActive = res.data.data.list || [];
            this.dataActive.forEach(function (item) {
              item.imgurl =
                process.env.VUE_APP_API_BASE_URL +
                "/download?fileCode=" +
                item.sysFldCoverpath;
              const timeOne = new Date(item.pubdate);
              item.date = timeOne.getFullYear();
              const month = (timeOne.getMonth() + 1).toString().padStart(2, 0);
              const day = timeOne.getDate().toString().padStart(2, 0);
              item.year = month + '-' + day
              // pubdate
              item.pubdate = moment(item.pubdate).format('YYYY-MM-DD')
            });
            // this.dataActiveCarousel = this.dataActive.slice(0, 3)
          }
        }
      });
    },
    // 媒体报道
    getNoteList(id) {
      let obj = {
        themeId: id,
        pageNumber: 1,
        pageSize: 14,
      };
      apiNote.getNoteList(obj).then((res) => {
        if (res.data.success) {
          this.dataReport = res.data.data.list;
          this.dataReport.map(function(item) {
            item.pubdate = moment(item.pubdate).format('YYYY-MM-DD')
            item.imgurl = process.env.VUE_APP_API_BASE_URL + "/download?fileCode=" + item.sysFldCoverpath
          })
        }
      });
    },
  },
};
</script>
<style lang="less">
@import "./NewsIndex.less";
</style>
